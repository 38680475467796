@import '../../globals/mixins';

.logo {
  height: $size-m;
  position: relative;
  width: $size-m;
  -webkit-transition: all $speed-default;
  transition: all $speed-default;

  &::before {
    content: ' ';
    height: $size-m;
    width: $size-m;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    border-radius: $size-xs;
  }

  &.medium {
    height: $size-l;
    width: $size-l;
    &::before {
      height: $size-l;
      width: $size-l;
    }
  }

  &.large {
    height: $size-xl;
    width: $size-xl;
    &::before {
      height: $size-xl;
      width: $size-xl;
    }
  }

  &.full {
    height: 200px;
    width: 200px;
  }

  &.typo {
    width: auto;
  }

  &.center {
    margin: 0 auto;
  }

  svg {
    display: block;
    height: 100%;
    overflow: visible;
    position: relative;
    top: 0;
    left: 0;
    text-align: left;
    path {
      fill: $color-white;
    }
    ellipse {
      fill: $color-dark;
    }
    .path {
      fill: url(#logo-gradient);
    }

    & > path,
    & > g {
      @include transition-all;
    }

    & > g:last-of-type {
      opacity: 0;
      // display: none;
    }
  }

  &.dark {
    svg {
      & > path:first-of-type {
        opacity: 0;
      }
      & > g:first-of-type:not(:only-of-type) {
        transform: translate(30px, $size-s) scale(1.3);
      }
      & > g:last-of-type:not(:only-of-type) {
        transform: translate(calc(-315.886px - #{$size-s}), -795.515px);
        path {
          fill: $color-dark;
        }
      }
    }
  }

  &.shadow {
    &::before {
      // Safari only
      @media not all and (min-resolution: 0.001dpcm) {
        @supports (-webkit-appearance: none) {
          box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
        }
      }
    }
    svg {
      & > path:first-of-type {
        // filter: url(#logo-shadow);
        -webkit-filter: drop-shadow(0 0 8px rgba(0, 0, 0, 0.2));
        filter: drop-shadow(0 0 8px rgba(0, 0, 0, 0.2));
        // box-shadow: 0 0 8px 0 rgba(0, 0, 0, .2);
      }
    }
  }

  &.typo {
    svg {
      & > g:last-of-type {
        opacity: 1;

        // display: block;
      }
    }
  }
}
