@import '../../globals/mixins';

.meta {
  @include no-list();
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: $size-s;
  height: 48px;
  width: auto;
  .metrics,
  .help,
  .search {
    display: none;
  }

  .help {
    &::after {
      position: absolute;
      content: '?';
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: $font-size-sm;
      color: inherit;
    }
  }

  & > li {
    margin-right: $size-xs;
    // &:nth-child(1),
    // &:nth-child(2),
    // &:nth-child(3) > ul > li:nth-child(1){
    // 	display: none;
    // }
    &:last-child {
      button {
        height: 40px;
        width: 80px;
        svg {
          @include transition-all;
        }
      }
    }
  }

  &.minified {
    .search {
      display: initial;
    }
    & > li {
      .help {
        &::after {
          color: $color-dark;
        }
      }
      &:last-child {
        button {
          height: $size-m;
          width: $size-xl;
        }
      }
    }
  }

  &.inCanvas {
    .metrics,
    .help {
      display: initial;
    }
    .help {
      &::after {
        color: $color-dark;
      }
    }
    & > li {
      &:last-child {
        button {
          height: $size-m;
          width: $size-m;
        }
      }
    }
  }

  &.logged {
    & > li {
      position: relative;
      &:last-child {
        &.count::after {
          content: attr(data-count);
          background: $color-red;
          border-radius: $size-s;
          color: $color-white;
          font-size: $font-size-xs;
          height: $size-sm;
          padding-top: $size-xxs;
          position: absolute;
          left: $size-xs;
          text-align: center;
          top: $size-xs;
          width: $size-sm;
        }
        button {
          svg {
            & > g > g:nth-of-type(2) {
              circle,
              path {
                stroke: $color-white;
              }
            }
            & > g > g:nth-of-type(1) {
              circle {
                // stroke: $color-white;
                fill: $color-subtl1;
              }
            }
          }
        }
      }
    }
    &.inCanvas {
      & > li {
        &:last-child {
          &.count::after {
            display: none;
          }
        }
      }
    }
    &.minified {
      & > li {
        &:last-child {
          &.count::after {
            border-radius: 50%;
            font-size: $font-size-xs;
            height: 20px;
            padding-top: 2px;
            left: 6px;
            top: 6px;
            width: 20px;
          }
        }
      }
    }
  }

  @include screen-s {
    .metrics,
    .help,
    .search {
      display: initial;
    }
  }

  @include screen-m {
    & > li {
      margin: 0 $size-xs;
      // &:nth-child(1),
      // &:nth-child(2),
      // &:nth-child(3) {
      // 	display: initial;
      // }
      &:nth-child(2) {
        margin-right: 4px;
      }
    }
  }

  @include screen-l {
    margin-right: $size-xs;
  }

  @include screen-xl {
    margin-right: -$size-xs;
  }
}
