@import '../../globals/mixins';

.navigation {
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  height: 0;
  opacity: 0;
  overflow: hidden;

  @include screen-m() {
    opacity: 1;
    height: auto;
  }

  &.minified {
    opacity: 0;
    height: 0;
  }

  ul {
    align-items: center;
    display: flex;
    justify-content: center;
    max-width: 70vw;
    width: 100%;
    margin: 0;
    list-style: none;
    @include screen-l() {
      padding: 0 $size-m;
    }
    @include screen-xl() {
      padding: 0 18%;
    }
    li {
      @include transition-all();
      margin: 0;
      padding: 0;
      position: relative;
      align-items: center;
      display: flex;
      flex-grow: 1;
      height: $size-l;
      justify-content: center;
      text-align: center;
      width: auto;
      &::after {
        @include transition-all();
        content: ' ';
        border-bottom: 2px solid $color-dark;
        width: 0;
        color: $color-dark;
        bottom: 0;
        position: absolute;
      }
      &.active,
      &:hover,
      &:active {
        &::after {
          width: $size-m;
        }
      }
      a {
        color: $color-dark;
        text-decoration: none;
        & > span {
          // Icon
          display: inline-block;
          float: none;
          height: $size-l;
          margin-left: $size-xs;
          position: relative;
          top: 50%;
          transform: translateY($size-s);
          width: $size-l;
        }
      }

      &.inbox {
        a {
          & > span {
            &::after {
              content: attr(data-count);
              background: $color-red;
              border-radius: $size-s;
              color: $color-white;
              font-size: $font-size-xxxs;
              height: $size-sm;
              padding-top: 3px;
              position: absolute;
              right: -$size-xs;
              text-align: center;
              top: 0;
              width: $size-sm;
            }
          }
        }
      }
    }
  }

  &.light {
    li {
      &::after {
        border-color: $color-white;
      }
      a {
        color: $color-white;
      }
    }
  }

  &.vertical {
    opacity: 1;
    height: auto;
    li {
      text-align: left;
      &::after {
        bottom: 4px;
        left: 0;
      }
    }
  }

  &.small {
    ul {
      li {
        padding: $size-xs 0;
        &::after {
          bottom: 0;
        }
      }
    }
  }
}
