@import '../../globals/vars';

.icon {
  float: left;
  height: 100%;
  // max-height: 48px;
  // max-width: 48px;
  min-height: $size-s;
  min-width: $size-s;
  width: 100%;
  position: relative;

  svg {
    fill: none;
    height: 100%;
    left: 0;
    position: absolute;
    stroke: $color-dark;
    stroke-linecap: round;
    stroke-linejoin: miter;
    stroke-miterlimit: 10;
    stroke-width: 1;
    top: 0;
    transform-box: fill-box;
    width: 100%;
    g {
      fill: none;
      display: block;
    }
  }

  &-marker {
    &_venue {
      transition: opacity 0.2s ease-in-out;
      transform-origin: bottom;
      opacity: 0.6;

      svg {
        overflow: visible;
        > defs > linearGradient {
          stop:nth-of-type(1) {
            stop-color: $color-blue;
          }
          stop:nth-of-type(2) {
            stop-color: $color-green;
          }
        }
        > path {
          stroke: none;
          stroke-width: none;
          transition: filter 0.2s ease-in-out;
        }
      }

      &[data-active] {
        svg > path {
          stroke: $color-dark;
          fill: $color-dark;
        }
      }

      &[data-plan='gold'] {
        opacity: 0.8;
        transform: scale(1.25);
        svg {
          > defs > linearGradient {
            stop:nth-of-type(1) {
              stop-color: $color-green;
            }
            stop:nth-of-type(2) {
              stop-color: $color-blue;
            }
          }
          > path {
            stroke: $color-gold !important;
            stroke-width: 2px;
            filter: drop-shadow(0px 0px 10px #00000099);
          }
        }
      }

      &[data-plan='platinum'] {
        opacity: 1;
        transform: scale(1.4);
        svg {
          > defs > linearGradient {
            stop:nth-of-type(1) {
              stop-color: $color-green;
            }
            stop:nth-of-type(2) {
              stop-color: $color-blue;
            }
          }
          > path {
            stroke: $color-gold !important;
            stroke-width: 3px;
            filter: drop-shadow(0px 0px 4px #00000099);
          }
        }

        &:hover {
          svg {
            > path {
              filter: drop-shadow(0px 0px 8px #000000c1);
            }
          }
        }
      }

      &:hover {
        opacity: 1;
      }
    }
  }
}
