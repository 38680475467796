@import '../../globals/mixins';

.metrics {
  color: $color-white;
  white-space: nowrap;
  display: flex;
  align-items: center;
  small {
    padding: 0 4px;
    &:first-child {
      cursor: pointer;
      opacity: 0.4;
    }
    &:last-child {
      cursor: default;
      opacity: 1;
    }
  }

  &.metric {
    small {
      &:first-child {
        cursor: default;
        opacity: 1;
      }
      &:last-child {
        cursor: pointer;
        opacity: 0.4;
      }
    }
  }

  &.dark {
    color: $color-dark;
    // small {
    // 	opacity: 1 !important;
    // 	&:first-child {
    // 		color: $color-subtl3;
    // 	}
    // 	&:last-child {
    // 		color: $color-dark;
    // 	}
    // }
    // &.metric {
    // 	small {
    // 		&:first-child {
    // 			color: $color-dark;
    // 		}
    // 		&:last-child {
    // 			color: $color-subtl3;
    // 		}
    // 	}
    // }
  }
}
