@import '../../globals/mixins';

.offset {
  &::-webkit-scrollbar {
    display: none;
  }
  // display: inline-flex;
  // align-items: center;
  &.xs {
    @include offset($size-xs);
  }

  &:not(.xs) {
    @include offset;
  }

  // float: left;
  display: inline-block;

  &.slide {
    width: calc(100vw - #{$size-s});
    display: inline-block;
    overflow-x: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    white-space: nowrap;
  }

  &.full {
    padding: 0;
    width: auto;
    left: calc(50% + #{$size-xs});
    transform: translateX(-50%);
  }
}
