@import '../../globals/mixins';

.btn {
  background: none;
  color: $color-dark;
  border-radius: $size-xs;
  border: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  height: auto;
  margin: $size-xs 0;
  min-height: $size-s;
  min-width: $size-s;
  padding: $size-xs 0;
  overflow: hidden;
  position: relative;
  vertical-align: top;

  &.inactive {
    pointer-events: none;
  }

  &.loading {
    > div {
      span {
        display: none;
      }
      Icon {
        content: 'da';
      }
    }
  }

  // -webkit-transition: all .2s;
  // transition: all .2s;
  @include transition-all();

  & > div {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end; //space-between;
    width: 100%;

    & > span {
      display: flex;
      text-align: left;

      &:nth-child(1) {
        width: 100%;
      }

      &:nth-child(2) {
        height: $size-s;
        width: $size-s;
      }

      &:nth-child(2):not(:last-child) {
        margin: 0 !important;
      }
      &:nth-child(3) {
        margin: 0 $size-s 0 4px !important;
      }
    }
  }

  &.center {
    & > div {
      & > span {
        text-align: center !important;
        justify-content: center !important;
      }
    }
  }

  /*
	* Widths
	*/
  &.auto {
    width: auto;
  }

  &.full {
    width: 100% !important;
    margin-left: 0;
    margin-right: 0;
    &.float {
      width: calc(100% - #{$size-s});
    }
  }

  &.half {
    width: calc(50% - #{$size-s});
  }

  /*
	* Heigths
	*/
  &.tiny {
    box-shadow: none;
    min-height: $size-xs;
    min-width: $size-xs;
    padding: $size-xxs 0;

    // border: 1px solid $color-subtl1;
    svg {
      stroke: $color-subtl1;
    }

    @include canHover() {
      &.hover,
      &:hover {
        // border: 1px solid $color-dark;
        color: $color-dark;
        svg {
          stroke: $color-dark;
        }
      }
    }

    &:not(.icOnly) > div {
      & > span {
        margin: 0 $size-xs; //$font-size
      }
    }
  }

  &.small {
    max-height: $size-m;
    box-shadow: inset 0 0 0 1px $color-subtl1;
    // border: 1px solid $color-subtl1;
    @include canHover() {
      &.hover,
      &:hover {
        box-shadow: inset 0 0 0 1px $color-dark;
        // border: 1px solid $color-dark;
        color: $color-dark;
      }
    }

    &:not(.icOnly) > div {
      & > span {
        margin: 0 $size-s; //$font-size

        &:nth-child(2) {
          margin-right: 12px; //.75em;
        }
      }
    }

    &.iconLarge {
      height: $size-ml;
      max-height: $size-ml;
      & > div {
        & > span:nth-child(2) {
          margin-right: $size-s;
          margin-left: 0;
          svg {
            transform: translateY(-50%);
            top: 50%;
            height: $size-sm;
            width: $size-sm;
          }
        }
      }
    }
  }

  &.medium {
    box-shadow: inset 0 0 0 1px $color-dark;
    color: $color-dark;
    min-height: $size-l;

    & > div {
      & > span {
        margin: 0 $size-s; //$font-size

        &:nth-child(2) {
          height: $size-m;
          width: $size-m;
          margin-right: 12px; //.75em;
        }
      }
    }

    &.iconLarge {
      & > div {
        & > span:nth-child(2) {
          margin-right: $size-sm; //.75em;

          svg {
            height: $size-m;
            width: $size-m;
          }
        }
      }
    }
  }

  &.large {
    min-height: $size-xl;
    font-size: $font-size-ss;
    @include screen-m() {
      font-size: $font-size;
    }

    @include screen-l() {
      font-size: $font-size-s;
    }

    & > div {
      span {
        margin: 0 $size-s;

        &:nth-child(2) {
          margin-right: 12px;
          margin-left: 0;
          height: $size-m;
          min-width: $size-m;
        }

        @media (min-width: $screen-s) {
          margin: 0 $size-m;
          &:nth-child(2) {
            margin-right: $size-sm;
          }
        }
      }
    }

    &.iconLarge {
      & > div {
        & > span:nth-child(2) {
          min-width: $size-l;
          flex-grow: unset;
          height: $size-l;
          margin: 0 $size-s 0 $size-xs;
          svg {
            stroke-width: 2;
            height: $size-l;
            width: $size-l;
          }
        }
      }
    }
  }

  &.fill {
    height: 100% !important;
    width: 100% !important;
  }

  &.shadow {
    @include shadow-m;
  }

  /*
	* Colors
	*/

  &.transparent {
    &:not(:hover) {
      background: none !important;
      color: $color-dark;

      &:not(.iconFill) {
        svg {
          stroke: $color-dark;
        }
      }

      &.iconFill {
        svg {
          stroke: none;
          path {
            stroke: none;
            fill: $color-dark;
          }
        }
      }
    }
  }

  &.dark,
  &.subtl {
    background: $color-dark;
    color: $color-white;
    box-shadow: none;

    svg {
      stroke: $color-white;
    }

    &[disabled] {
      background: $color-subtl3;
    }

    &::before {
      background-image: $color-gradient-ltr;
    }
  }

  &.subtl {
    background: $color-subtl1;
    &.hover,
    &:hover {
      background: $color-dark;
      box-shadow: none;
    }
    &::before {
      background-image: none;
    }
  }

  &.white {
    background: $color-white;
    color: $color-dark;
    &:not(.shadow) {
      box-shadow: inset 0 0 0 1px $color-subtl1;
    }

    svg {
      * {
        stroke: $color-dark;
      }
    }

    @include canHover() {
      &:hover {
        background: $color-white;
        &:not(.shadow) {
          box-shadow: inset 0 0 0 1px $color-dark;
        }
        color: $color-dark;
        svg {
          * {
            stroke: $color-dark;
          }
        }
      }
    }
  }

  &.gold {
    background: $color-gold;
    border: none;
    box-shadow: none;
    color: $color-dark;
    svg {
      * {
        stroke: $color-dark;
      }
    }

    @include canHover() {
      &.hover,
      &:hover {
        background: $color-dark;
        color: $color-white;
        svg {
          * {
            stroke: $color-white;
          }
        }
      }
    }
  }

  &.red {
    background: none;
    box-shadow: inset 0 0 0 1px $color-red;
    color: $color-red;
    svg {
      * {
        stroke: $color-red;
      }
    }

    @include canHover() {
      &.hover,
      &:hover {
        box-shadow: inset 0 0 0 1px $color-red;
        background: $color-red;
        color: $color-white;
        svg {
          * {
            stroke: $color-white;
          }
        }
      }
    }
  }

  /*
	* Types
	*/
  &.toggle {
    min-height: $size-l;

    &:not(.dark) {
      color: $color-grey;
      box-shadow: inset 0 0 0 1px $color-subtl1;
    }

    & > div {
      & > span {
        margin: 0 $size-s; //$font-size
        &:nth-child(2) {
          height: calc(8px * 3);
          min-width: calc(8px * 3);
          margin-right: 12px; //.75em;
        }
      }
    }
    &:not(.dark) {
      svg {
        stroke: $color-subtl1;
      }

      @include canHover() {
        &.hover,
        &:hover {
          box-shadow: inset 0 0 0 1px $color-dark;
          color: $color-dark;

          &:not(.iconFill) {
            svg {
              stroke: $color-dark;
            }
          }

          &.iconFill {
            svg {
              stroke: none;
              path {
                stroke: none;
                fill: $color-dark;
              }
            }
          }
        }
      }
    }

    // & > div {
    // 	span {
    // 		justify-content: center;
    // 		text-align: center;
    // 	}
    // }
  }

  &.iconLeft {
    & > div {
      flex-direction: row-reverse;
      span {
        // justify-content: flex-end;
        &:nth-child(2) {
          margin-right: 0;
          margin-left: 12px;
        }
      }
    }
    &.large > div {
      span {
        &:nth-child(2) {
          margin-left: 24px;
        }
      }
    }
  }

  &.dashed {
    border-width: 1px;
    border-style: dashed;
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }
  }

  &.round {
    border-radius: 50%;
  }

  &.icOnly {
    height: $size-m;
    width: $size-m;
    &.tiny {
      height: $size-s;
      width: $size-s;
    }

    &.large {
      height: $size-xl;
      width: $size-xl;
    }

    margin: 0;
    padding: 0;

    & > div {
      height: 100%;
      width: 100%;
      padding: 0;
      margin: 0;

      & > span {
        height: 100%;
        width: 100%;
        padding: 0;
        margin: 0;
      }
    }

    &.medium {
      height: $size-l;
      width: $size-l;
      & > div {
        & > span {
          margin: $size-xs;
          width: $size-m;
        }
      }
    }

    &.iconLarge {
      height: $size-ml;
      max-height: $size-ml;
      & > div {
        & > span:nth-child(2) {
          margin-right: $size-sm;
          margin-left: 0;
          svg {
            transform: translateY(-50%);
            top: 50%;
            height: $size-m;
            width: $size-m;
          }
        }
      }
    }
  }

  &.text {
    border: none;
    box-shadow: none;

    &:not(.icOnly) > div {
      span {
        margin: 0 $size-s 0 0;
        &:nth-child(2) {
          margin-right: 12px;
        }
      }
    }

    &.small:not(.icOnly) > div {
      span {
        margin: 0 $size-xs 0 0;
      }
    }

    &.hover,
    &:hover {
      border: none;
      box-shadow: none;
      text-decoration: underline;
    }
  }

  &.float {
    span {
      &:nth-child(1):not(:only-child) {
        // display: inline-block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        // margin-right: 0;
      }
    }

    &.small {
      max-height: $size-m;
      // max-width: 144px;
      span {
        &:nth-child(1):not(:only-child) {
          margin-right: $size-xs;
        }
        &:nth-child(2):not(:only-child) {
          margin-left: 0;
        }
      }
    }

    &:nth-of-type(odd),
    &:nth-of-type(even) {
      margin: $size-xs;
    }
  }

  &.more {
    background: none;
    border: none;
    box-shadow: inset 0 0 0 1px $color-dark;
    color: $color-dark;
  }

  &.colored {
    @include canHover() {
      border: none;
      overflow: visible;

      // &.medium {
      // 	box-shadow: inset 0 0 0 1px $color-dark;
      // }

      // &[disabled] {
      // 	box-shadow: inset 0 0 0 1px $color-subtl1;
      // }

      & > div {
        z-index: 2;
        // & > span {
        // 	&:nth-child(2) {
        // 		height: calc(#{$size-xs} * 3);
        // 		min-width: calc(#{$size-xs} * 3);
        // 	}
        // }
      }

      &::before {
        background-image: $color-gradient-rtl;
        position: absolute;
        content: '';
        border-radius: $size-xs;
        top: 0;
        bottom: 0;
        width: 0;
        left: 0;
        z-index: 1;
        opacity: 0;
        @include transition-all-bounce();
      }

      &.hover,
      &:hover {
        border: none;
        box-shadow: none;
        color: $color-white;
        text-decoration: none;

        &:not(.iconFill) {
          svg {
            stroke: $color-white;
            path {
              stroke: $color-white;
            }
          }
        }

        &.iconFill {
          svg {
            stroke: none;
            path {
              stroke: none;
              fill: $color-white;
            }
          }
        }

        &::before {
          width: 100%;
          opacity: 1;
        }
      }
    }
  }

  /*
	* States
	*/
  &[disabled] {
    // box-shadow: none !important;
    color: $color-grey;
    pointer-events: none;
    &:not(.text) {
      box-shadow: inset 0 0 0 1px $color-subtl1;
      // border: 1px solid $color-subtl1;
    }
    svg {
      stroke: $color-subtl1;
    }
  }

  &.blocked {
    background: none;
    color: $color-red;
    // box-shadow: none !important;
    pointer-events: none;
    &:not(.text) {
      box-shadow: inset 0 0 0 1px $color-red;
      // border: 1px solid $color-red;
    }
    svg {
      stroke: $color-red;
    }
  }

  &.active:not(.dark) {
    box-shadow: inset 0 0 0 1px $color-dark;
    color: $color-dark;

    &:not(.iconFill) {
      svg {
        stroke: $color-dark;
      }
    }

    &.iconFill {
      svg {
        stroke: none;
        path {
          stroke: none;
          fill: $color-dark;
        }
      }
    }
  }
}
