@import '../../globals/mixins';

.header {
  @include transition-all();
  background: none;
  height: auto;
  opacity: 1;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  padding-top: $size-l;
  z-index: 3;

  &.hidden {
    opacity: 0;
    pointer-events: none;
    z-index: 2;
  }

  & > div {
    position: relative;
    align-items: center;
    justify-content: space-between;
    display: flex;
    max-height: $size-l;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: $screen-larger-grid;
    padding: 0 $size-xs;

    & > nav {
      display: none;
    }

    @include screen-m {
      padding: 0 $size-s 0 $size-m;
    }

    @include screen-l {
      & > nav {
        display: initial;
        & > ul {
          font-size: 1em;
        }
      }
    }

    @include screen-xl {
      padding: 0 $size-xxxl;
    }
  }

  &.dark {
    &.bg {
      background: $color-dark;
    }
    &.minified {
      background: $color-dark 0% 0% no-repeat padding-box;
      @include bg-blur;
    }
    small {
      color: $color-white;
    }
    button {
      svg {
        stroke: $color-white;
        * {
          stroke: $color-white;
        }
      }
      &::after {
        color: $color-white;
      }
    }
  }

  &.light {
    &.bg {
      background: $color-white;
    }
    &.minified {
      // background: $color-white 0% 0% no-repeat padding-box;
      background: rgba(255, 255, 255, 0.4);
      backdrop-filter: blur(15px);
      -webkit-backdrop-filter: blur(15px);
    }
    small {
      color: $color-dark;
    }
    button {
      svg {
        stroke: $color-dark;
        * {
          stroke: $color-dark;
        }
      }
      &::after {
        color: $color-dark;
      }
    }
  }

  &.minified {
    padding: $size-xs 0;

    & > div {
      max-height: $size-m;
      & > ul:last-child {
        & > li {
          &:nth-child(3) {
            display: initial;
          }
        }
      }
    }
  }

  // Logo
  &.dark,
  &.minified {
    & > div {
      & > div:first-child {
        // Logo
        svg {
          margin-left: $size-xs;
        }
      }
    }
  }
  &:not(.dark):not(.minified) {
    @include screen-m {
      & > div {
        & > div:first-child {
          // Logo
          svg {
            margin-left: -$size-xs;
          }
        }
      }
    }
  }
  &.minified {
    @include screen-m {
      & > div {
        & > div:first-child {
          // Logo
          svg {
            margin-left: -$size-xxs;
          }
        }
      }
    }
  }
}
